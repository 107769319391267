<script setup lang="ts">
import { setCookie } from 'nookies';
import { getTourName, getTourSteps, tourNames } from '~/helpers/tours/index';
import type { TourStep } from '~/typings/types';

type Props = {
    name?: string | null;
};

//  NOTE: Only required if the tour is not route based.
const props = withDefaults(defineProps<Props>(), {
    name: null,
});

const isTourEnabled = import.meta.env.VITE_IS_TOUR_ENABLED;

const route = useRoute();
const tours = ref();
const steps = ref<TourStep[]>([]);
const tourName = computed(() => props.name ?? getTourName(route.name?.toString()) ?? null);

function startTour(): void {
    if (tourName.value) {
        tours.value[tourName.value].start();
    }
}

watch(() => tourName.value, async (value: string | null) => {
    if (!value) return;

    steps.value = await getTourSteps(value);

    const tourFinished = document.cookie.indexOf(`${value}Tour=`);

    if (tourFinished === -1 && value) {
        setCookie(null, `${value}Tour`, 'true', {
            maxAge: 30 * 24 * 60 * 60,
            secure: process.env.NODE_ENV === 'production',
            path: '/',
        });

        startTour();
    }
}, { immediate: true });

onMounted(() => {
    const instance = getCurrentInstance();

    if (instance) {
        tours.value = instance.appContext.config.globalProperties.$tours;
    }
});
</script>

<template>
    <template v-if="isTourEnabled">
        <button
            v-if="tourName !== null"
            type="button"
            class="relative h-8 w-8 grid place-content-center cursor-pointer bg-white border
        rounded-md shadow-md shadow-transparent transition-shadow outline-none border-wgm-gray-400
        focus:border-sky-400 focus:shadow-sky-200"
            @click="() => startTour()"
        >
            <IconQuestionMark
                class="text-wgm-gray-800 fill-current"
                height="20"
                width="20"
            />
        </button>
        <Teleport to="body">
            <v-tour
                v-for="n in (props.name ? [props.name] : tourNames)"
                :key="n"
                :name="n"
                :steps="steps"
                :callbacks="[]"
                :options="{ highlight: true }"
            >
                <template #default="tour">
                    <transition name="fade">
                        <v-step
                            v-if="tour.steps[tour.currentStep]"
                            :key="tour.currentStep"
                            ref="tourRef"
                            :step="tour.steps[tour.currentStep]"
                            :previous-step="tour.previousStep"
                            :next-step="tour.nextStep"
                            :stop="tour.stop"
                            :skip="tour.skip"
                            :is-first="tour.isFirst"
                            :is-last="tour.isLast"
                            :labels="tour.labels"
                            :highlight="tourName !== 'sidebar'"
                        >
                            <template #header>
                                <div class="mb-2 flex justify-between">
                                    <div class="mr-2 font-semibold">
                                        {{ tour.currentStep + 1 }} / {{ steps.length }}
                                    </div>
                                    <Button
                                        theme="link"
                                        size="sm"
                                        class="text-wgm-blue-300"
                                        @click="tour.stop"
                                    >
                                        {{ $t('forms.actions.close') }}
                                    </Button>
                                </div>
                            </template>
                            <template #actions>
                                <div class="flex justify-end gap-4">
                                    <Button
                                        v-if="!tour.isFirst"
                                        theme="link"
                                        size="sm"
                                        class="text-wgm-blue-300"
                                        @click="tour.previousStep"
                                    >
                                        {{ $t('forms.pagination.previous') }}
                                    </Button>
                                    <Button
                                        v-if="tour.isLast"
                                        theme="link"
                                        size="sm"
                                        class="text-wgm-blue-300"
                                        @click="tour.stop"
                                    >
                                        {{ $t('forms.actions.close') }}
                                    </Button>
                                    <Button
                                        v-else
                                        theme="link"
                                        size="sm"
                                        class="text-wgm-blue-300"
                                        @click="tour.nextStep"
                                    >
                                        {{ $t('forms.pagination.next') }}
                                    </button>
                                </div>
                            </template>
                        </v-step>
                    </transition>
                </template>
            </v-tour>
        </Teleport>
    </template>
</template>

<style lang="css">
#app .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
    @apply p-4;
    @apply mt-4;
    @apply mb-16;
}

.overflow-toggle-tour {
  @apply overflow-hidden;
}

body.v-tour--active .overflow-toggle-tour {
  @apply overflow-visible;
}
.overflow-toggle-tour-scroll {
  @apply overflow-x-hidden;
  @apply overflow-y-scroll;
}

body.v-tour--active .overflow-toggle-tour-scroll {
  @apply overflow-visible;
}
</style>
